<template>
  <input
    :type="type"
    :placeholder="placeholder"
    :name="name"
    :minlength="minlength"
    :maxlength="maxlength"
    :size="size"
    :value="value"
    :class="classes"
    :style="style"
    @click="$emit('click', $event)"
    @input="updateValue($event.target.value)"
  />
</template>

<script>
import "./mkp-input.scss";

export default {
  name: "MkpInput",
  props: {
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      default: null
    },
    minlength: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    size: {
      type: Number,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    }
  },
  data: () => {
    return {
      classes: {
        "mkp-input": true
      },
      style: {
        placeholder: true
      }
    };
  },
  methods: {
    updateValue: function(value) {
      let emitCorrectType = {
        number: Number(value),
        text: String(value),
        boolean: Boolean(value),
        password: String(value)
      };

      let arrayType = ["number", "text", "boolean", "password"];

      arrayType.findIndex(element => element === this.type) > -1
        ? this.$emit("input", emitCorrectType[this.type])
        : console.error("Type unknown if you want you can add type in array");
    }
  }
};
</script>

<template>
  <div :class="classes">
    <label class="label-password">{{ label }}</label>
    <mkp-input
      v-model="name"
      type="password"
      :placeholder="$t(placeholder)"
      autocomplete="on"
      class="input-password"
      @input="v.$touch()"
    />
    <div v-if="v.$error">
      <div v-if="v.required != undefined && !v.required" class="error-password">
        {{ $t("forms.components.password.errors.required") }}
      </div>
      <div v-if="v.minLength != undefined && !v.minLength">
        {{ $t("forms.components.password.errors.minlength.start") }}
        {{ v.$params.minLength.min }}
        {{ $t("forms.components.password.errors.minlength.end") }}
      </div>
      <div v-if="v.sameAsPassword != undefined && !v.sameAsPassword">
        {{ $t("forms.components.password.errors.sameAs") }}
      </div>
    </div>
  </div>
</template>

<script>
import MkpInput from "../../../simple/input/mkp-input";
import "./inputsform.scss";
export default {
  name: "MkpPasswordComponent",
  components: { MkpInput },
  props: {
    placeholder: {
      type: String,
      default: "forms.components.password.placeholder"
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    classes() {
      return {
        errorRed: this.v.$error
      };
    }
  }
};
</script>
